.o-modal {
    $root: &;
    height: 100%;
    outline: 0;
    position: relative;
    width: 100%;

    &:focus {
        outline: 0;
    }

    &_close {
        position: absolute;
        top: rem(16);
        right: rem(16);
        background-color: transparent;
        outline: 0;
        border: 0;

        @include mobile-down {
            top: rem(4);
            right: rem(4);
        }
    }

    &_main {
        align-items: center;
        display: flex;
        height: 100%;
        max-width: rem(1366);
        margin-left: auto;
        margin-right: auto;
        overflow: hidden;
        padding: rem(12);
        width: 100%;
        justify-content: center;

        &-small {
            max-width: rem(768);
        }
    }

    &_wrapper {
        position: relative;
        border-radius: rem(16);
        background-color: $white;
        display: flex;
        flex-direction: column;
        padding: rem(24);
        width: 100%;
        z-index: 103;

        @include mobile-down {
            padding: rem(40) rem(16) rem(16) rem(16);
        }
    }

    &_body {
        flex: 1;
    }

    &_button {
        margin-top: rem(20);
        @include adjust-flex(center, center);
    }

    &-red {
        #{$root}_wrapper {
            background-color: $maroon;
            max-width: rem(640);
            width: 100%;

            @include mobile-down {
                max-width: rem(356);
            }
        }

        #{$root}_close {
            path {
                fill: $white !important;
            }
        }
    }

    &-default {
        #{$root}_wrapper {
            z-index: 104;
            background-color: $maroon;
            max-width: rem(1024);
            width: 100%;
            max-height: calc(100vh - 120px);

            @include mobile-down {
                max-width: rem(480);
            }
        }
        #{$root}_close {
            path {
                fill: $white !important;
            }
        }
        #{$root}_body {
            overflow-y: auto;
        }
    }

    &-success {
        #{$root}_wrapper {
            padding: rem(16);
            background-color: transparent;
            max-width: rem(512);
            max-height: rem(512);
            background-image: url('~assets/images/modal-bg.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            width: 100%;
            height: 100%;

            @include mobile-down {
                max-width: rem(356);
                max-height: rem(356);
            }
        }

        #{$root}_close {
            path {
                fill: $white !important;
            }
        }

        #{$root}_body {
            @include adjust-flex(flex-start, center);
            flex-direction: column;
        }

        &_img {
            width: 80%;
            height: 80%;
            transform: rotate(10deg);
        }
    }
}

.ReactModal__Overlay {
    opacity: 0;
    transform: translateX(-100px);
    transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
    transform: translateX(0);
}

.ReactModal__Overlay--before-close {
    opacity: 0;
    transform: translateX(-100px);
}

.ReactModal__Overlay {
    background: rgba(34, 34, 34, 0.7);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    position: fixed;
    transform: translateY(-100%);
    transition: all 500ms ease-in-out;
    z-index: $z-modal;

    &::after {
        background: rgba(34, 34, 34, 0.3);
        content: '';
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 102;
    }

    &--open {
        overflow: hidden;
    }

    &--after-open {
        opacity: 1;
        transform: translateY(0);
    }

    &--before-close {
        opacity: 0;
        transform: translateY(-100%);
    }
}
