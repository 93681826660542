.p-home {
    height: 100vh;
    overflow: hidden;
    background-color: $carmine-red;

    &_modalContent {
        color: $white;
        @include font-base(16, 18);

        @include mobile-down {
            @include font-base(14, 16);
        }

        a {
            color: $white;
            text-decoration: underline;
        }
    }

    &-info {
        max-width: rem(384);
        margin-left: auto;
        margin-right: auto;

        &-link {
            text-decoration: underline;
            cursor: pointer;
            font-weight: 600;
        }
    }

    .o-container {
        padding-top: rem(4);
    }

    &_header {
        img {
            width: 100%;
            max-width: 100%;
        }
    }

    &_container {
        position: relative;
        background-image: url('~assets/images/body-pc.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom center;
        overflow: hidden;
        height: 100%;
        width: 100%;

        @include mobile-down {
            background-image: url('~assets/images/body-mb.png');
        }
    }

    &_tabs {
        margin-top: rem(8);
        margin-bottom: rem(24);
        @include adjust-flex(center, center);

        &-item {
            border: 0 !important;
            border-width: 0 !important;
            padding: rem(4) !important;
            position: relative;
            max-width: rem(200);
        }

        &-text {
            color: $venetian-red;
            padding: rem(16);
            background-color: $white !important;
            @include font-base(14, 20);
            font-weight: 700;
            text-transform: uppercase;
            border-radius: rem(8);

            @include mobile-down {
                padding: rem(8);
            }
        }

        &-active {
            border: 0;
            border-width: 0;

            &::after {
                content: '';
                position: absolute;
                bottom: rem(-8);
                left: 50%;
                transform: translateX(-50%);
                width: calc(100% - 40px);
                height: rem(2);
                background-color: $white;
            }
        }
    }

    &_giftsList {
        @include adjust-flex(flex-start, stretch);
        flex-wrap: wrap;
        overflow-y: scroll;
        margin: rem(-4);
    }

    &_giftWrapper {
        padding: rem(8);
        flex: 0 0 50%;
        max-width: 50%;
        @include mobile-down {
            flex: 0 0 100%;
            max-width: 100%;
        }

        &-item {
            padding: rem(16);
            border-radius: rem(8);
            background-color: $maroon;
            height: 100%;
            @include adjust-flex(flex-start, center);

            &-disabled {
                cursor: not-allowed;
                opacity: 0.7;
            }

            &-img {
                max-width: rem(140);
                max-height: rem(140);
                width: 100%;
                margin-right: rem(16);
                background-color: $platinum;
                border-radius: rem(12);
                background-image: url('~assets/images/star.png');
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center center;
                overflow: hidden;

                @include mobile-down {
                    flex: 0 0 100%;
                }

                img {
                    max-width: 100%;
                    width: 100%;
                    border-radius: rem(12);
                }
            }

            &-contentWrapper {
                width: 100%;
                @include adjust-flex(space-between, flex-start);
                flex-direction: column;
                flex: 1;

                @include mobile-down {
                    height: 100%;
                    @include adjust-flex(space-around, flex-start);
                }
            }

            &-content {
                p {
                    margin-top: rem(4);

                    &:first-child {
                        margin-top: 0;
                    }
                }
            }

            &-btn {
                margin-top: rem(24);
                width: rem(180);

                @include mobile-down {
                    width: rem(144);
                    margin-top: rem(8);
                }

                @include small-mobile {
                    width: rem(120);
                }

                button {
                    width: 100%;
                }
            }
        }
    }


    &_historyWrapper {
        padding: rem(8);
        flex: 0 0 100%;
        max-width: 100%;

        &-item {
            padding: rem(16);

            border-radius: rem(8);
            background-color: $maroon;
            @include adjust-flex(flex-start, center);

            &-img {
                max-width: rem(156);
                width: 100%;
                margin-right: rem(16);
                background-color: $platinum;
                border-radius: rem(12);
                background-image: url('~assets/images/star.png');
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center center;
                overflow: hidden;

                @include mobile-down {
                    flex: 0 0 100%;
                    max-width: rem(140);
                    max-height: rem(140);
                }

                @include small-mobile {
                    max-width: rem(140);
                }

                img {
                    max-width: 100%;
                    width: 100%;
                    border-radius: rem(12);
                }

                &-center {
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            &-contentWrapper {
                width: 100%;
                @include adjust-flex(flex-start, flex-start);
                flex-direction: column;
            }

            &-btn {
                margin-top: rem(24);
                width: rem(200);

                @include mobile-down {
                    width: rem(120);
                    margin-top: rem(8);
                }

                button {
                    width: 100%;
                }
            }

            &-content {
                @include mobile-down {
                    width: 100%;
                }
            }
        }
    }

    &_historyList {
        @include adjust-flex(flex-start, center);
        flex-wrap: wrap;
        overflow-y: scroll;
        margin: rem(-4);

        &-name {
            margin-left: rem(16);
            @include mobile-down {
                margin-left: 0;
            }
        }

        &-content {
            @include adjust-flex(flex-start, center);
        }

        &-qr {
            margin-top: rem(24);
            max-width: rem(256);
            margin-left: auto;
            margin-right: auto;
            background-color: $white;
            padding: rem(16);
            border-radius: rem(16);
        }
    }

    &_btnGroup {
        margin-top: rem(8);
        @include adjust-flex(center , center);

        &-item {
            padding: rem(8);
        }
    }
}
