html,
body {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    overflow-x: hidden;
}

* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent; //issue click in mobile flash background highligh
}

button {
    &:focus {
        outline: none;
    }
}

p {
    margin-bottom: 0;
    margin-top: 0;
}

a {
    text-decoration: none;
}

[role='button'],
button {
    cursor: pointer;

    &:focus {
        outline: none;
    }
}

input {
    word-break: normal;
}

ul,
ol {
    margin: 0;
    padding: 0;
}

.hidden-animation {
    visibility: hidden;
}

.overflow-hidden {
    height: 100vh;
}

.u-mt {
    &-4 {
        margin-top: rem(4);
    }

    &-24 {
        margin-top: rem(24);
    }

    &-16 {
        margin-top: rem(16);
    }
}

.u-ml {
    &-4 {
        margin-left: rem(4);
    }

    &-24 {
        margin-left: rem(24);
    }

    &-16 {
        margin-left: rem(16);
    }
}

.u-mr {
    &-4 {
        margin-right: rem(4);
    }

    &-24 {
        margin-right: rem(24);
    }

    &-16 {
        margin-right: rem(16);
    }
}

.u-mb {
    &-4 {
        margin-bottom: rem(4);
    }

    &-8 {
        margin-bottom: rem(8);
    }

    &-24 {
        margin-bottom: rem(24);
    }

    &-16 {
        margin-bottom: rem(16);
    }
}

.color {
    @include style-color();
}

.fs {
    @include style-font-base();
}

.fw {
    @include style-font-weight();
}

.mainBackground-top {
    position: relative;
    background-image: url('~assets/images/background-pc-top.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    overflow: hidden;
    height: 100%;
    width: 100%;

    @include mobile-down {
        background-image: url('~assets/images/background-mobile-top.png');
    }
}

.mainBackground-bottom {
    position: relative;
    background-image: url('~assets/images/background-pc-bottom.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;
    overflow: hidden;
    height: 100%;
    width: 100%;

    @include mobile-down {
        background-image: url('~assets/images/background-mobile-bottom.png');
    }
}

.center-absolute {
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
}

.bg-contain {
    background-size: contain !important;
    background-position: center !important;
}

.center {
    text-align: center;
}

.w-100 {
    width: 100%;
}


.notfound {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @include adjust-flex();
}
