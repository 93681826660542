.p-policy {
    &_logo {
        max-width: rem(256);
        @include mobile-down {
            margin: 0 auto;
        }
    }

    &_title {
        text-align: center;
        @include font-base(24, 30);
        color: $bdazzled-blue;

        @include mobile-down {
            @include font-base(16, 28);
        }

        &-divider {
            margin-top: rem(32);
            margin-left: auto;
            margin-right: auto;
            width: rem(256);
            height: rem(1);
            border-bottom: rem(1) dashed $bdazzled-blue;
            @include mobile-down {
                margin-top: rem(24);
                width: rem(128);
            }
        }
    }

    &_content {
        margin-top: rem(24);
        padding-bottom: rem(16);
        font-weight: 500;
        @include mobile-down {
            margin-top: rem(16);
        }

        ul {
            margin-left: rem(32);
        }

        a {
            text-decoration: underline;
        }
    }
}
