.m-datePicker {
    width: 100%;

    &_customInput {
        padding: rem(12);
        border-radius: rem(20);
        border: 1px solid $white;
        height: rem(36);
        color: $white;
        @include font-base(14, 20);
        display: flex;
        align-items: center;
    }
}
