.p-login {
    &_subHeading {
        margin-top: rem(8) !important;
    }

    .a-button {
        width: fit-content;
        margin-top: rem(20);
        margin-left: auto;
        margin-right: auto;
    }

    &_form {
        margin-top: rem(30);

        &_errorText {
            margin-top: rem(8);
            @include font-base(14, 20);
        }
    }
}
