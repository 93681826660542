// Text Colors
$white: rgb(255, 255, 255); // #ffffff
$black: rgb(0, 0, 0); // #000000
$fire-engine-red: rgb(198, 30, 30); //#c61e1e
$white-smoke: rgb(244, 246, 250); //#f4f6fa
$davys-grey: rgb(89, 89, 89); //#595959
$platinum: rgb(222, 224, 234); // #dee0ea
$isabelline: rgb(237, 237, 237); //#ededed
$venetian-red: rgb(209, 15, 15); //#d10f0f
$vivid-vermilion: rgb(240, 91, 40); //#f05b28
$la-salle-green: rgb(0, 113, 37); //#007125
$banana-yellow: rgb(255, 219, 32); //#ffdb20
$harlequin-green: rgb(32, 195, 0); //#20c300
$maroon: rgb(114, 0, 0); //#720000
$kelly-green: rgb(82, 196, 26); //#52c41a
$carmine-red: rgb(250, 2, 58); //#fa023a
$platinum: rgb(230, 230, 230); //#e6e6e6
$khaki: rgb(239, 239, 141); //#efef8d
$bdazzled-blue: rgb(47, 84, 150); //#2f5496
// Overlays
$black08: rgba($black, 0.8); //#d10f0f

// Linears
