.a-button {
    height: rem(44) !important;
    position: relative;
    border-radius: rem(8) !important;
    padding-left: rem(32) !important;
    padding-right: rem(32) !important;
    background-color: $white;

    @include mobile-down {
        height: rem(36) !important;
        padding-left: rem(24) !important;
        padding-right: rem(24) !important;
    }

    @include small-mobile {
        padding-left: rem(16) !important;
        padding-right: rem(16) !important;
    }

    &:disabled {
        cursor: not-allowed;
    }

    &-red {
        background-color: $venetian-red !important;
    }

    &-outline {
        border: 1px solid $venetian-red !important;
    }

    &-m {
        height: rem(20) !important;
        padding-left: rem(6) !important;
        padding-right: rem(6) !important;

        p {
            @include font-base(14, 16); }
    }

    &-l {
        height: rem(20) !important;
        padding-left: rem(32) !important;
        padding-right: rem(32) !important;

        p {
            @include font-base(14, 16);
        }
    }
}
