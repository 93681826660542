.o-otp {
    position: relative;

    &_wrapper {
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }

    &_input {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-top: rem(20);
    }

    &_error {
        display: flex;
        align-items: center;
        background-color: rgba(1, 104, 67, 0.58);
        border-radius: rem(15);
        width: fit-content;
        margin-left: auto;
        padding: rem(4) rem(10);
        margin-top: rem(8);

        &-text {
            color: $banana-yellow;
            @include font-base(11, 11);
            margin-left: rem(8) !important;
        }
    }

    .custom-input {
        position: relative;
        margin-left: rem(6);
        margin-right: rem(6);
        width: rem(46);
        height: rem(46);
        padding: rem(4) !important;
        box-sizing: border-box;
        border: 1px solid $white;
        border-radius: rem(12);
        background-color: transparent;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: -1px;
            border-radius: inherit;
        }

        input {
            text-align: center;
            font-size: rem(30);
            line-height: rem(37);
            font-weight: 300;
            background: transparent;
            color: $white;
        }
    }

    &_button {
        margin-top: rem(16);

        button {
            max-width: rem(200);
            margin: rem(16) auto;
        }
    }

    &_noReceive,
    &_resend {
        letter-spacing: rem(0.2);
    }

    &_resendTextWrap {
        text-align: center;
    }

    &_resend {
        position: relative;
        width: fit-content;
        margin-left: auto !important;
        margin-right: auto !important;
        color: $white;
    }

    &_resendText {
        font-weight: 600;
        text-decoration: underline;
        cursor: pointer;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    /* stylelint-disable-next-line selector-no-qualifying-type */
    input[type=number] {
        -moz-appearance: textfield;
    }
}
