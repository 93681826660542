.o-container {
    margin-left: auto;
    margin-right: auto;
    padding-left: rem(32);
    padding-right: rem(32);
    padding-top: rem(60);

    @include mobile-down {
        padding-left: rem(16);
        padding-right: rem(16);
    }

    @include small-mobile-up {
        max-width: rem(540);
    }

    @include mobile-up {
        max-width: rem(720);
    }

    @include tablet-up {
        max-width: rem(960);
    }

    @include desktop-up {
        max-width: rem(1140);
    }

    @include break-min(1366px) {
        max-width: 1366px;
        padding-left: rem(120);
        padding-right: rem(120);
    }

    &-noPaddingRight {
        padding-right: 0;
    }

    &-noPaddingLeft {
        padding-left: 0;
    }

    &-fullscreen {
        padding-left: 0;
        padding-right: 0;
    }

    img {
        max-width: 100%;
        width: auto;
        height: auto;
    }
}
