.a-input {
    input {
        width: 100%;
        height: rem(36);
        border-radius: rem(40);
        background-color: transparent;
        border: 1px solid $white;
        padding: rem(12);
        color: $white;
        @include font-base(14, 20);

        &::placeholder {
            color: $white;
        }

        &:focus {
            outline: none;
        }
    }
}
